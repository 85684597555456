import React, { useContext, useEffect, useState } from 'react'
import LoggedInWrapper from '../components/loggedinWrapper'
import AppContext from '../utils/context'
import SvgArrow from '../components/svg/arrow'
import Link from '../components/link'
import { getCurrentTeamData } from '../utils/team-data'
import API from '../utils/api'
import Notification from '../components/notification'

const NotificationsPage = () => {

  const [notificationData, setNotificationData] = useState([])

  useEffect(() => {
    setNotificationData(localStorage.getItem(`notifications`) ? JSON.parse(localStorage.getItem(`notifications`)) : [])
  }, [])

  return (
    <LoggedInWrapper>
      <div className="site-sub-header px-4 py-3">
        <div className="row">
          <div className="col-2 d-flex align-items-center">
            <Link to={`/`} className={`back-arrow`}>
              <SvgArrow />
            </Link>
          </div>
          <div className="col-10 text-right">
            <h1 className={`title h5 smaller text-uppercase mb-0 d-inline-block`}>Notifications</h1>
          </div>
        </div>
      </div>

      <div className="container pt-4">
        {notificationData.map((notification, index) => (
          <div key={index}>
            <Notification notification={notification} />
          </div>
        ))}
        {notificationData.length === 0 && (
          <div className="text-center">
            <p className="title h5 smaller">You currently have no notifications!</p>
          </div>
        )}
      </div>
    </LoggedInWrapper>
  )
}

export default NotificationsPage