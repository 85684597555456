import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Collapse from 'react-bootstrap/Collapse'

var relativeTime = require(`dayjs/plugin/relativeTime`)
dayjs.extend(relativeTime)

const Notification = ({ notification }) => {

  const timeNow = dayjs()
  const timeAdded = dayjs(notification.timestamp)
  const timeSince = timeNow.to(timeAdded)
  const [open, setOpen] = useState(false)

  return (
    <div className="card">
      <div
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="card-notification-header py-0 px-0"
        style={{
          backgroundColor: `transparent`,
          cursor: `pointer`,
        }}>
        <div className="d-flex">
          <div
            className="px-3 pt-4"
            style={{
              backgroundColor: `#EBE6DD`,
            }}>
            <div>
              <svg height="23" viewBox="0 0 33 23" width="33" xmlns="http://www.w3.org/2000/svg">
                <g fill="#c5bdad" fillRule="evenodd">
                  <path d="m27.6742201 0c-.8416449 0-1.5252263.67639621-1.5252263 1.50919456v18.73980674c0 .8327983.6835814 1.5091945 1.5252263 1.5091945.841645 0 1.5252264-.6763962 1.5252264-1.5091945v-18.73980674c0-.83279835-.6835814-1.50919456-1.5252264-1.50919456zm-16.1226246 15.1660548v-8.57489721c0-.22775073-.1858001-.4116049-.4159772-.4116049l-5.75567159.0013733c-.84164494 0-1.52522634.67639621-1.52522634 1.50919456v6.37971795c0 .8327983.6835814 1.5091945 1.52522634 1.5091945h5.75567159c.23017 0 .4159772-.1838471.4159772-.4116049zm21.0324272-4.6980349h-1.7456918c-.2301701 0-.4159773.1838471-.4159773.4116049 0 .2277577.1858001.4116049.4159773.4116049h1.7456918c.2301701 0 .4159773-.1838472.4159773-.4116049 0-.2277578-.1858001-.4116049-.4159773-.4116049zm-1.4904758-3.4641686c.1053805 0 .2135296-.03978719.2939493-.12073486l1.2354372-1.22108167c.1622272-.16052204.1622272-.42119344 0-.58170495-.1622272-.16052205-.4256677-.16052205-.5878842 0l-1.2354373 1.22108166c-.1622272.16052205-.1622272.42119345 0 .58170496.0804197.07957437.1871845.12073486.2939492.12073486zm.2939067 7.8710349c-.1622273-.160522-.4256677-.160522-.5878843 0-.1622272.1605221-.1622272.4211935 0 .581705l1.2354373 1.2224514c.1622272.1605221.4256676.1605221.5878842 0 .1622272-.160522.1622272-.4211934 0-.5817049z" />
                  <path d="m12.3832661 15.792998-2.72044662 2.4558613v-1.84806h-3.91840015v3.9924165c0 1.43786 1.18134139 2.6067842 2.63447541 2.6067842h.86798296c.23017007 0 .41597727-.1838471.41597727-.4116049v-3.2255438l3.52741203-3.1843798 12.1282631 3.4505409v-17.50102314l-12.9340216 3.67982298v9.98402676zm-12.3832661-6.53355606v3.23790706c0 .8327983.68358139 1.5091945 1.52522634 1.5091945h1.49750399v-6.25608538h-1.49750399c-.84164495 0-1.52522634.67639621-1.52522634 1.50919456z" />
                </g>
              </svg>
            </div>
          </div>
          <div className="px-3  py-3">
            <h5
              className="card-title mb-2"
              style={{
                color: `#273A41`,
                letterSpacing: `0.7px`,
                fontSize: `16px`,
              }}>
              {notification.title}
            </h5>
            <h6
              className="card-subtitle mb-2 text-muted"
              style={{
                color: `#8E9597`,
              }}>
              {timeSince}
            </h6>
          </div>
        </div>
      </div>
      <Collapse in={open}>
        <div id="collapseText">
          <div
            className="card-body py-2 px-3"
            style={{
              borderTop: `1px solid #979797`,
            }}>
            <p className="">{notification.message}</p>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

Notification.propTypes = {
  notification: PropTypes.object,
}

export default Notification